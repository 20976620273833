import React from "react";
import FormPost2 from "../assets/images/form post doc Image[2].jpg";
import FormPost3 from "../assets/images/form post doc Image[3].jpg";
import FormPost4 from "../assets/images/form post doc Image[4].jpg";
import FormPost5 from "../assets/images/form post doc Image[5].jpg";
import FormPost6 from "../assets/images/form post doc Image[6].jpg";
import FormPost66 from "../assets/images/form post doc Image[66].jpg";
import FormPost7 from "../assets/images/form post doc Image[7].jpg";
import FormPost77 from "../assets/images/form post doc Image[77].jpg";
import FormPost8 from "../assets/images/form post doc Image[8].jpg";
import FormPost88 from "../assets/images/form post doc Image[88].jpg";
import FormPost9 from "../assets/images/form post doc Image[9].jpg";
import FormPost10 from "../assets/images/form post doc Image[10].jpg";
import FormPost100 from "../assets/images/form post doc Image[100].jpg";
import FormPost11 from "../assets/images/form post doc Image[11].jpg";
import FormPost12 from "../assets/images/form post doc Image[12].jpg";
import FormPost122 from "../assets/images/form post doc Image[122].jpg";
import FormPost13 from "../assets/images/form post doc Image[13].jpg";
import FormPost133 from "../assets/images/form post doc Image[133].jpg";
import FormPost14 from "../assets/images/form post doc Image[14].jpg";
import FormPost144 from "../assets/images/form post doc Image[144].jpg";
import FormPost15 from "../assets/images/form post doc Image[15].jpg";
import FormPost16 from "../assets/images/form post doc Image[16].jpg";
import FormPost17 from "../assets/images/form post doc Image[17].jpg";
import FormPost18 from "../assets/images/form post doc Image[18].jpg";
import FormPost19 from "../assets/images/form post doc Image[19].jpg";
import FormPost199 from "../assets/images/form post doc Image[199].jpg";
import Ministryplace4 from "../assets/images/ministry_place_docs[1] Image[4].jpg";
import Ministryplace13 from "../assets/images/ministry_place_docs[1] Image[13].jpg";
import Ministryplace133 from "../assets/images/ministry_place_docs[1] Image[133].jpg";

import Sidebar from "../components/Sidebar";

export default function FormPost() {
  //   const settings = {
  //     dots: true,
  //     dotsClass: "slick-dots slick-thumb",
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 3,
  //     initialSlide: 0,
  //     adaptiveHeight: true,
  //     // variableWidth: true,
  //     className: "slides",
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: 3,
  //           slidesToScroll: 3,
  //           infinite: true,
  //           dots: true,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 2,
  //           initialSlide: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 480,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //         },
  //       },
  //     ],
  //   };

  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="ministryplace">
                <header className="docs-header">
                  <h1 className="docs-heading">Form Post</h1>
                  <section className="docs-intro docs-section">
                    <p>
                      Vigoplace offers a versatile feature called Form Post,
                      allowing users to create customizable forms for various
                      purposes, including course registration, association
                      enrolment, school admissions, training sign-ups, and more.
                      Form Posts are available in both Paid and Free formats,
                      and users must have an active wallet to create a Paid Form
                      Post. This documentation provides a comprehensive guide on
                      how to create, manage, and utilize Form Posts across all
                      Place types on Vigoplace.
                    </p>
                  </section>
                </header>

                <section className="docs-section" id="create">
                  <h3>Creating a Form Post</h3>
                  <ol>
                    <li>
                      <p>Navigate to Form Post Section</p>

                      <p>- Log in to your Vigoplace account.</p>

                      <p>
                        - Go to the desired Place type (Basic Place, Channel
                        Place, Contest Place, Market Place, Ministry Place, or
                        Group Place) where you want to create the form.
                      </p>

                      <p>
                        - Select the "Add Post" option and choose "Form Post"
                        from the list.
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost2}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <p>Customize Form</p>
                      <p> - Title: Enter a descriptive title for your form.</p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost3}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Description: Provide detailed information about the
                        form’s purpose and instructions for completion.
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost4}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        -Form action button: which are the button users press to
                        be able to answer the form question
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost5}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Select either "Free Form Post" or "Paid Form Post"
                        based on your needs.
                      </p>

                      <div className="simplelightbox-gallery row">
                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={FormPost6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={FormPost66}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>

                      <p>
                        <b>-NOTE: </b>Ensure you have an active wallet if you
                        are opting for a Paid Form Post.
                      </p>
                      <p>
                        - Payment Options (for Paid Form Posts): Set the fee for
                        form submission and specify the payment methods
                        accepted.
                      </p>

                      <div className="simplelightbox-gallery row">
                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={FormPost7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={FormPost77}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>

                      <p>
                        - Question: Add various fields to input various
                        questions you want to ask on the form.
                      </p>

                      <div className="simplelightbox-gallery row">
                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={FormPost8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={FormPost88}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>

                      <p>- Add a media to your form</p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost9}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <p> Review and Publish</p>
                      <p>- Review all the details you have entered.</p>
                      <p>
                        - Click the icon button sign at the top right of the
                        screen to make the form live.
                      </p>

                      <div className="simplelightbox-gallery row">
                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={FormPost10}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={FormPost100}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>

                      <p>Managing Form Posts</p>
                      <ol>
                        <li>Edit Form Post</li>

                        <p>- Navigate to the Place where the form is posted.</p>
                        <p>- Select the specific Form Post you want to edit.</p>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={FormPost11}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>
                          - Click on the vertical option button at the top right
                          of the post.
                        </p>

                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost12}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost122}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <p>
                          {" "}
                          - Click the "Edit" button and make the necessary
                          changes.
                        </p>

                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost13}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost133}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <p> - Save the changes to update the form.</p>

                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost14}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost144}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <li>Fill a form</li>

                        <p>
                          {" "}
                          - Got to the Form Post and click on the form responds
                          button which is at the bottom left of the Form Post
                          media
                        </p>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={FormPost15}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>
                          {" "}
                          - Make the payment required for a Paid Form Post to be
                          able to answer the form.
                        </p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={FormPost16}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>- Answer the questions of the Form Post.</p>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={FormPost17}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>
                          - Accept the terms and conditions before clicking on
                          the “Submit” button to submit your answers{" "}
                        </p>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={FormPost18}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li> View Responses</li>
                        <p>
                          {" "}
                          - Go to the Form Post and select the "View Responses".
                        </p>

                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost19}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={FormPost199}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <p>- Review the responses submitted by users.</p>
                        <p>
                          - Export submission data by downloading the responses
                          if needed for further analysis.
                        </p>
                      </ol>
                    </li>

                    <li>
                      <p> Delete Form Post</p>
                      <p> - Navigate to the Place where the form is posted.</p>
                      <p>
                        {" "}
                        - Select the specific Form Post you want to delete.
                      </p>
                      <p>
                        - Click the "Delete" button to remove the form from the
                        Place.
                      </p>
                    </li>

                    <li>
                      <p> Confirmation and Notifications</p>
                      <p>
                        - Users receive a confirmation message upon successful
                        submission.
                      </p>
                      <p>
                        - Notifications may be sent to both the form creator and
                        the user regarding the submission status.
                      </p>
                    </li>
                  </ol>

                  <p>Wallet Integration for Paid Form Posts</p>

                  <ul>
                    <li> Setting Up Wallet</li>
                    <p> - Ensure your Vigoplace wallet is set up and funded.</p>
                    <p>
                      {" "}
                      - Link your wallet to your Vigoplace account if not
                      already done.
                    </p>

                    <li>Payment Processing</li>
                    <p>
                      {" "}
                      - Users filling out a Paid Form Post will be prompted to
                      make a payment.
                    </p>
                    <p>
                      {" "}
                      - Select the payment method and complete the transaction.
                    </p>
                    <p>
                      {" "}
                      - The form submission will be confirmed once the payment
                      is processed.
                    </p>
                  </ul>

                  <h2>Support and Assistance</h2>
                  <p>
                    For any issues or questions related to Form Posts, users can
                    access support through the following channels:
                  </p>
                  <p>
                    - Ticket System: Submit a support ticket via the Vigoplace
                    app.
                  </p>
                  <p>
                    - Support Email: Contact support at
                    admin.place@Vigoplace.com.
                  </p>

                  <h2>Conclusion</h2>
                  <p>
                    Form Posts on Vigoplace provide a flexible and efficient way
                    to gather information for various purposes. By following
                    this documentation, users can easily create, manage, and
                    utilize Form Posts, enhancing their interaction and
                    engagement on the platform.
                  </p>
                </section>

                {/* <!--//section--> */}
              </article>
              {/* <!--//docs-article--> */}
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}
