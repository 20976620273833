import logo from "./logo.svg";
import "./App.css";
import "./assets/css/theme.css";
import "./assets/css/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MainRoutes from "./routes/main.routes";
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import WhiteSvg from "./assets/images/white.svg";
import Home from "./pages/Home";
import Docs from "./pages/Docs";
import Contestplace from "./pages/Contestplace";
import Sidebar from "./components/Sidebar";
import { useState } from "react";
import ChannelPlace from "./pages/channelplace";
import GiftPost from "./pages/GiftPost";
import Wallet from "./pages/Wallet";
import WalletPin from "./pages/WalletPin";
import Postactivities from "./pages/Postactivities";
import Verifications from "./pages/verifications";
import Pricing from "./pages/Pricing";
import Earnings from "./pages/Earnings";
import NewsPost from "./pages/NewsPost";
import SupportandDonatePost from "./pages/SupportandDonatePost";
import FundraisingPost from "./pages/FundraisingPost";
import MinistryPlace from "./pages/Ministryplace";
import FormPost from "./pages/FormPost";
import GroupPlace from "./pages/Groupplace";
import BasicPlace from "./pages/BasicPlace";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Home/>,
//   },
//   {
//     path: "docs",
//     element: <Sidebar />,
//     children: [
//       {
//         path: "/docs/marketplace",
//         element: <Docs />,
//       },
//       {
//         path: "/docs/contestplace",
//         element: <Contestplace />,
//       },
//     ],
//   },
// ]);

function App() {
  const [showSidebar, setShowsidebar] = useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/docs",
      element: (
        <Sidebar showSidebar={showSidebar} setShowsidebar={setShowsidebar} />
      ),

      children: [
        {
          path: "/docs/marketplace",
          element: <Docs />,
        },
        {
          path: "/docs/contestplace",
          element: <Contestplace />,
        },
        {
          path: "/docs/channelplace",
          element: <ChannelPlace />,
        },
        {
          path: "/docs/ministryplace",
          element: <MinistryPlace />,
        },
        {
          path: "/docs/groupplace",
          element: <GroupPlace />,
        },
        {
          path: "/docs/basicplace",
          element: <BasicPlace />,
        },
        {
          path: "/docs/giftpost",
          element: <GiftPost />,
        },
        {
          path: "/docs/newspost",
          element: <NewsPost />,
        },
        {
          path: "/docs/formpost",
          element: <FormPost />,
        },
        {
          path: "/docs/supportanddonatepost",
          element: <SupportandDonatePost />,
        },
        {
          path: "/docs/fundraisingpost",
          element: <FundraisingPost />,
        },
        {
          path: "/docs/wallet",
          element: <Wallet />,
        },
        {
          path: "/docs/walletpin",
          element: <WalletPin />,
        },
        {
          path: "/docs/postactivities",
          element: <Postactivities />,
        },
        {
          path: "/docs/verifications",
          element: <Verifications />,
        },
        {
          path: "/docs/pricing",
          element: <Pricing />,
        },
        {
          path: "/docs/earnings",
          element: <Earnings />,
        },
      ],
    },
  ]);

  return (
    <>
      <header className="header fixed-top">
        {/* <div className="branding docs-branding">
  <div className="navinfo">
    <div className="site-logo">
      <a className="navbar-brand" href="index.html">
        <img className="logo-icon me-2" src={WhiteSvg} alt="logo" />
      </a>
    </div>

    <a href="#download" className="btn d-none d-lg-flex download">
      Download
    </a>
  </div>
</div> */}

        <div className="branding docs-branding">
          <button
            id="docs-sidebar-toggler"
            className="docs-sidebar-toggler docs-sidebar-visible me-2 d-xl-none"
            type="button"
            onClick={() => setShowsidebar((showSidebar) => !showSidebar)}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div className="navinfo">
            <div className="site-logo">
              <a className="navbar-brand" href="/">
                <img className="logo-icon me-2" src={WhiteSvg} alt="logo" />
              </a>
            </div>
            <a href="#download" className="btn d-none d-lg-flex download">
              Download
            </a>
          </div>
        </div>
      </header>
      <RouterProvider router={router} />
      {/* <MainRoutes /> */}
    </>
  );
}

export default App;
