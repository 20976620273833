import React from "react";

export default function Pricing() {
  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="pricing">
                <section className="docs-section" id="pricing">
                  <h1 className="docs-heading">PRICING</h1>
                  <h5>Vigoplace Pricing Overview </h5>
                  <p>
                    At Vigoplace, we offer a flexible, transparent, and
                    comprehensive pricing structure designed to accommodate a
                    wide variety of transactions, including wallet funding,
                    payouts, donations, and earnings from posts. Whether you're
                    an individual user or a business, our platform simplifies
                    financial operations with competitive fees, multi-currency
                    support, and secure transactions. Below is a detailed
                    breakdown of our charges for various transaction types,
                    allowing you to maximize the value you get from Vigoplace
                    while ensuring ease of use and security for all your
                    financial needs.
                  </p>

                  <h5>Key Features of Vigoplace's Financial System </h5>
                  <ol>
                    <li>Multi-Wallet System </li>
                    <p>
                      Vigoplace supports multiple currencies, including Naira
                      (NGN) and US Dollars (USD), in a single wallet system.
                      This allows for effortless management of local and
                      international transactions on one platform. Whether you
                      are making payouts, funding your wallet, or earning
                      through activities on Vigoplace, you can switch between
                      currencies seamlessly, ensuring that your financial needs
                      are covered regardless of the currency you prefer.
                    </p>

                    <li> Transparent Transaction Fees</li>
                    <p>
                      Vigoplace provides clear and competitive fees for all
                      types of transactions, from payouts and wallet funding to
                      earnings and bank transfers. Our goal is to ensure that
                      users understand the costs upfront, with no hidden fees,
                      and can make informed decisions on their financial
                      operations
                    </p>

                    <li>Secure and Efficient Transactions</li>
                    <p>
                      All financial transactions are safeguarded with
                      industry-standard security features, including OTP
                      (One-Time Password) authentication for transfers. Whether
                      you’re moving funds between your wallets, transferring to
                      bank accounts, or processing earnings, our robust system
                      ensures that every transaction is secure.
                    </p>

                    <li> Flexible Payout Options </li>
                    <p>
                      Vigoplace offers two primary payout methods for both NGN
                      and USD—Regular and Expedited transfers. Regular transfers
                      are perfect for standard transactions, while Expedited
                      transfers are designed for faster withdrawals with a
                      slightly higher fee, catering to urgent needs.
                    </p>

                    <li> Optimized for Earnings </li>
                    <p>
                      Users can monetize various types of posts such as Gift
                      Posts, Voting Posts, and Fundraising Posts, and collect
                      earnings through Vigoplace’s secure wallet system. Our
                      fees for earnings are competitive, allowing you to keep
                      more of what you earn.
                    </p>
                  </ol>
                </section>
              </article>

              <section className="docs-section" id="fundingwallet">
                <br />
                <br />
                <br />
                <h1>Transactions Pricing</h1>
                <br />
                <h1 className="docs-heading">Funding wallet</h1>
                <br />
                <h5>Funding Naira wallet using a Naira Card</h5>
                <p>Card processing fee (CPF) = 1.5% + 100</p>
                <p>Funding charge (FC) = 10% of Card processing fee</p>
                <p>Total charge = CPF + FC</p>
                <p>Note: 100 fee is waved for transactions under 2,500</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Card processing fee = 15 (1.5% of 1000)</p>
                <p>Funding charge = 1.5 (10% of 15)</p>
                <p>Total fee = 16.5</p>
                <p>Funds to be received = 983.5 (1000 – 16.5)</p>
                <br />

                <h5>Funding US dollar wallet using a USD card</h5>
                <p>Card processing fee (CPF) = 2.9% + a fixed 0.3 cent</p>
                <p>Funding charge (FC) = 10% of Card processing fee (CPF)</p>
                <p>Total charge = CPF + FC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Card processing fee = 29 (2.9% of 1000)</p>
                <p>Funding charge = 2.9 (10% of 29)</p>
                <p>Total fee = 31.9</p>
                <p>Funds to be received = 961 (1000 – 31.9)</p>
                <br />

                <h5>Funding US dollar wallet using a non-US card</h5>
                <p>Card processing fee (CPF) = 4.9% + a fixed 0.3 cent</p>
                <p>Funding charge (FC) = 10% of Card processing fee (CPF)</p>
                <p>Total charge = CPF + FC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Card processing fee = 49 (4.9% of 1000)</p>
                <p>Funding charge = 4.9 (10% of 49)</p>
                <p>Total fee = 53.9</p>
                <p>Funds to be received = 946.1(1000 – 53.9)</p>

                <br />
                <h5>Funding via virtual account</h5>
                <p>Virtual account charge (VAC) = 1%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 100</p>
                <p>Charge amount = 1 (1% of 100)</p>
                <p>Funding charge = 0.1 (10% of 1)</p>
                <p>Total Fee = 1.1</p>
                <p>Funds to be received = 98.9 (100 – 1.1)</p>
              </section>

              <section className="docs-section" id="banktransfer">
                <br />
                <br />
                <br />
                <br />
                <h1 className="docs-heading">Bank Transfer</h1>
                <br />
                <p>For USD with a capped fee of $30</p>
                <h5>Regular or Normal Bank Transfer for USD:</h5>
                <p>Takes 2-3 business days before Bank Transfer is completed</p>
                <p>Bank Transfer charge (BTC) = 2%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Bank Transfer charge = 20 (2% of 1000)</p>

                <p>Total fee = 20</p>
                <p>Funds to be received = 980 (1000 – 20)</p>
                <br />

                <h5>Expedited Bank Transfer for USD</h5>
                <p>Takes 1-2 business days before Bank Transfer is completed</p>
                <p>Bank Transfer charge (BTC) = 3%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Bank Transfer charge = 30 (3% of 1000)</p>
                <p>Transfer fee = 30</p>
                <p>Funds to be received = 970 (1000 – 30)</p>
                <br />

                <h5>Regular or Normal Bank Transfer for NGN:</h5>
                <p>Takes 2-3 business days to be completed</p>
                <br />
                <h6>Transaction range - From 0 - 5000</h6>
                <br />
                <p>Card processing fee (CPF) = 10</p>
                <p>
                  Bank Transfer charge (BTC) = 10% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Bank Transfer charge = 10 (10% of 1000)</p>
                <p>Transfer fee = 10</p>
                <p>Total fee = 11</p>
                <p>Funds to be received = 989 (1000 – 11)</p>

                <br />

                <h6>Transaction range - From 5,001 - 50,000</h6>
                <br />
                <p>Card processing fee (CPF) = 25</p>
                <p>
                  Bank Transfer charge (BTC) = 10% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 10,000</p>
                <p>Card processing fee = 25</p>
                <p>Bank Transfer charge = 2.5 (10% of 25)</p>
                <p>Total fee = 27.5</p>
                <p>Funds to be received = 9,972.5 (10,000 – 27.5)</p>

                <br />

                <h6>Transaction range - Above 50,000</h6>
                <br />
                <p>Card processing fee (CPF) = 50</p>
                <p>
                  Bank Transfer charge (BTC) = 10% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 100,000</p>
                <p>Card processing fee = 50</p>
                <p>Bank Transfer charge = 5 (10% of 50)</p>
                <p>Total fee = 55</p>
                <p>Funds to be received = 99,945 (100,000 – 55)</p>

                <br />

                <h5>Expedited Bank Transfer for NGN:</h5>
                <p>Takes 1-2 business days to be completed</p>
                <br />
                <h6>Transaction range - From 0 - 5000</h6>
                <br />
                <p>Card processing fee (CPF) = 10</p>
                <p>
                  Bank Transfer charge (BTC) = 20% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Card processing fee = 10</p>
                <p>Bank Transfer charge = 2 (20% of 10)</p>
                <p>Total fee = 12</p>

                <p>Funds to be received = 988 (1000 – 12)</p>

                <br />

                <h6>Transaction range - From 5,001 - 50,000</h6>
                <br />
                <p>Card processing fee (CPF) = 25</p>
                <p>
                  Bank Transfer charge (BTC) = 20% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 10,000</p>
                <p>Card processing fee = 25</p>
                <p>Bank Transfer charge = 5 (20% of 25)</p>
                <p>Total fee = 30</p>
                <p>Funds to be received = 9,970 (10,000 – 30)</p>

                <br />

                <h6>Transaction range - Above 50,000</h6>
                <br />
                <p>Card processing fee (CPF) = 50</p>
                <p>
                  Bank Transfer charge (BTC) = 20% of Card processing fee (CPF)
                </p>
                <p>Total charge = CPF + BTC</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 100,000</p>
                <p>Card processing fee = 50</p>
                <p>Bank Transfer charge = 10 (20% of 50)</p>
                <p>Total fee = 60</p>
                <p>Funds to be received = 99,940 (100,000 – 60)</p>

                <br />
              </section>

              <section className="docs-section" id="chargesonearnings">
                <br />
                <br />
                <br />
                <br />
                <h1 className="docs-heading">Charges on Earnings</h1>
                <br />
                <h5>Earnings from Products or Services</h5>
                <p>Product and service charge (P&SC) = 5%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Charge amount = 50 (5% of 1000)</p>

                <p>Total fee = 50</p>
                <p>Funds to be received = 950 (1000 – 50)</p>
                <br />

                <h5>Earnings from Gift Post</h5>
                <p>Gift charge (GC) = 10%</p>

                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Gift charge amount = 100 (10% of 1000)</p>
                <p>Total fee = 100</p>
                <p>Funds to be received = 900 (1000 – 100)</p>
                <br />

                <h5>Earnings from voting Post</h5>
                <p>Voting charge (VC) = 5%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Charge amount = 50 (5% of 1000)</p>
                <p>Total fee = 50</p>
                <p>Funds to be received = 950 (1000 – 50)</p>

                <h5>Earnings from subscribing, renting and buying:</h5>
                <p>Channel place charge (CPC) = 5%</p>
                <p>Form post charge (FPC) = 5%</p>
                <p>Offering post charge (OPC) = 5%</p>
                <p>Collection post charge (CPC) = 5%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Charge amount = 50 (5% of 1000)</p>
                <p>Total fee = 50</p>

                <p>Funds to be received = 950 (1000 – 50)</p>

                <br />

                <h5>Earnings from Fundraising Post</h5>

                <h6>USD Fundraising</h6>
                <br />
                <p>USD Fundraising charge (FRC) = 6%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1000</p>
                <p>Total fee = 60</p>
                <p>Funds to be received = 940</p>

                <br />

                <h6>NGN Fundraising</h6>
                <br />
                <p>NGN Fundraising charge (FRC) = 5%</p>
                <p>
                  <b>Example:</b>
                </p>
                <p>Transaction amount = 1</p>
                <p>Charge amount = 50 (5% of 1000)</p>
                <p>Total fee = 50</p>
                <p>Funds to be received = 950</p>
              </section>
            </div>
            <p className="mt-4">
              Vigoplace is committed to ensuring transparency in all our
              financial dealings. Our fees are designed to be clear,
              competitive, and in line with providing top-tier services for all
              users. Whether you're an individual, business, or organization,
              Vigoplace’s financial system is built to support your goals with
              maximum convenience and security
            </p>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}
