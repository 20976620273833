import React from "react";
import Ministryplace1 from "../assets/images/ministry_place_docs[1] Image[1].jpg";
import Ministryplace2 from "../assets/images/ministry_place_docs[1] Image[2].jpg";
import Ministryplace3 from "../assets/images/ministry_place_docs[1] Image[3].jpg";
import Ministryplace4 from "../assets/images/ministry_place_docs[1] Image[4].jpg";
import Ministryplace5 from "../assets/images/ministry_place_docs[1] Image[5].jpg";
import Ministryplace6 from "../assets/images/ministry_place_docs[1] Image[6].jpg";
import Ministryplace66 from "../assets/images/ministry_place_docs[1] Image[66].jpg";
import Ministryplace7 from "../assets/images/ministry_place_docs[1] Image[7].jpg";
import Ministryplace8 from "../assets/images/ministry_place_docs[1] Image[8].jpg";
import Ministryplace9 from "../assets/images/ministry_place_docs[1] Image[9].jpg";
import Ministryplace10 from "../assets/images/ministry_place_docs[1] Image[10].jpg";
import Ministryplace11 from "../assets/images/ministry_place_docs[1] Image[11].jpg";
import Ministryplace12 from "../assets/images/ministry_place_docs[1] Image[12].jpg";
import Ministryplace13 from "../assets/images/ministry_place_docs[1] Image[13].jpg";
import Ministryplace133 from "../assets/images/ministry_place_docs[1] Image[133].jpg";
import Ministryplace14 from "../assets/images/ministry_place_docs[1] Image[14].jpg";
import Ministryplace15 from "../assets/images/ministry_place_docs[1] Image[15].jpg";
import Ministryplace155 from "../assets/images/ministry_place_docs[1] Image[155].jpg";
import Ministryplace16 from "../assets/images/ministry_place_docs[1] Image[16].jpg";
import Ministryplace17 from "../assets/images/ministry_place_docs[1] Image[17].jpg";
import Ministryplace18 from "../assets/images/ministry_place_docs[1] Image[18].jpg";
import Ministryplace188 from "../assets/images/ministry_place_docs[1] Image[188].jpg";
import Ministryplace19 from "../assets/images/ministry_place_docs[1] Image[19].jpg";
import Ministryplace20 from "../assets/images/ministry_place_docs[1] Image[20].jpg";
import Ministryplace21 from "../assets/images/ministry_place_docs[1] Image[21].jpg";
import Ministryplace211 from "../assets/images/ministry_place_docs[1] Image[211].jpg";
import Ministryplace22 from "../assets/images/ministry_place_docs[1] Image[22].jpg";
import Ministryplace23 from "../assets/images/ministry_place_docs[1] Image[23].jpg";
import Ministryplace24 from "../assets/images/ministry_place_docs[1] Image[24].jpg";
import Ministryplace25 from "../assets/images/ministry_place_docs[1] Image[25].jpg";
import Ministryplace255 from "../assets/images/ministry_place_docs[1] Image[255].jpg";
import Ministryplace26 from "../assets/images/ministry_place_docs[1] Image[26].jpg";
import Ministryplace27 from "../assets/images/ministry_place_docs[1] Image[27].jpg";
import Ministryplace277 from "../assets/images/ministry_place_docs[1] Image[277].jpg";
import Ministryplace28 from "../assets/images/ministry_place_docs[1] Image[28].jpg";
import Ministryplace29 from "../assets/images/ministry_place_docs[1] Image[29].jpg";
import Ministryplace30 from "../assets/images/ministry_place_docs[1] Image[30].jpg";
import Ministryplace31 from "../assets/images/ministry_place_docs[1] Image[31].jpg";
import Ministryplace32 from "../assets/images/ministry_place_docs[1] Image[32].jpg";
import Ministryplace322 from "../assets/images/ministry_place_docs[1] Image[322].jpg";
import Ministryplace33 from "../assets/images/ministry_place_docs[1] Image[33].jpg";
import Ministryplace34 from "../assets/images/ministry_place_docs[1] Image[34].jpg";
import Ministryplace35 from "../assets/images/ministry_place_docs[1] Image[35].jpg";
import Ministryplace36 from "../assets/images/ministry_place_docs[1] Image[36].jpg";
import Ministryplace37 from "../assets/images/ministry_place_docs[1] Image[37].jpg";
import Ministryplace38 from "../assets/images/ministry_place_docs[1] Image[38].jpg";
import Ministryplace39 from "../assets/images/ministry_place_docs[1] Image[39].jpg";
//import Sidebar from "../components/Sidebar";

export default function MinistryPlace() {
  //   const settings = {
  //     dots: true,
  //     dotsClass: "slick-dots slick-thumb",
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 3,
  //     initialSlide: 0,
  //     adaptiveHeight: true,
  //     // variableWidth: true,
  //     className: "slides",
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: 3,
  //           slidesToScroll: 3,
  //           infinite: true,
  //           dots: true,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 2,
  //           initialSlide: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 480,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //         },
  //       },
  //     ],
  //   };

  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="ministryplace">
                <header className="docs-header">
                  <h1 className="docs-heading">Ministry Place</h1>
                  <section className="docs-intro docs-section">
                    <p>
                      Ministry Place is a unique feature of Vigoplace designed
                      to help ministries, religious organizations, and
                      faith-based communities connect, share, and grow together.
                      This documentation provides a comprehensive guide on how
                      to effectively use Ministry Place to maximize its
                      benefits.
                    </p>
                  </section>
                </header>

                <section className="docs-section" id="create">
                  <h3>Features</h3>
                  <ol>
                    <li>
                      <b>Profile Creation</b>
                      <p>
                        Ministry Place allows for the creation of detailed
                        profiles for ministries. These profiles can include:
                      </p>

                      <p>
                        - Navigate to the Place creation section by clicking on
                        the top left “+” icon, click on Ministry Place
                      </p>

                      <div className="simplelightbox-gallery row">
                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Ministryplace1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Ministryplace2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Ministryplace3}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>

                      <p>
                        - Ministry Image: The profile photo of ministry place
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace4}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Ministry name: The profile name of the ministry place
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace5}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Ministry Address (Country, state): The exact address
                        where the place is been created or where the ministry is
                        located.
                      </p>

                      <div className="simplelightbox-gallery row">
                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Ministryplace6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Ministryplace66}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Ministryplace7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>

                      <p>
                        - Profile category: The category or type of the ministry
                        being created
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace8}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Description of the ministry’s mission and activities
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace9}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Give button to set up the currency you want your
                        givers to give in
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace10}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Request button set up the currency and amount for your
                        requests
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace11}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>- Click on the “Create place” to create the place.</p>
                    </li>

                    <li>
                      <p> Post Types</p>

                      <p>
                        <b>Note:</b> Dedicated to managing church and ministry
                        activities with a 5% charge on all transactions.
                      </p>

                      <p>
                        Ministry Place supports various post types tailored to
                        the needs of ministries:
                      </p>

                      <p>
                        - Basic Post: Share updates, announcements, and
                        inspirational messages
                      </p>

                      <p>
                        <b>Bible verse Post: </b> Promote upcoming events such
                        as services, fundraisers, and community gatherings.
                      </p>

                      <ul style={{ listStyleType: "disc" }}>
                        <li>Click on the “Add post” to make a post</li>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace12}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          Select the Bible version you want to make the post
                          with
                        </li>

                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace13}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace133}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <li>Select the book you want</li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace14}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>Choose a chapter and verse</li>
                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace15}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace155}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <li>
                          You can add a write up to your post which is optional{" "}
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace16}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          Add a media of your choosing either photo or a video{" "}
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace17}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          Click on the icon at the top right corner of the
                          screen to complete your post
                        </li>
                        <li>To view your post, go to your profile</li>

                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace18}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace188}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <p>
                          <b>- Offering Post: </b>Collect offerings for specific
                          causes or projects
                        </p>

                        <li>
                          Click on the “Add post” to make a post, click on
                          “Offering post”
                        </li>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace19}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>Write the description of your offering post</li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace20}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          Select any or all the currency you want your givers to
                          make their offering with
                        </li>
                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace21}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace211}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <li>Add a media to your offering post</li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace22}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          Click on the icon at the top right corner of the
                          screen to complete your post
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace23}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          You can view your givers by going to the post then
                          click on the “All Givers” text at the bottom left
                          corner of the media to view all your givers
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace24}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          To make an offering, click on the “Give” text at the
                          bottom right of the media to make your offering. You
                          can make the payment using your wallet or debit card,
                          after choosing a means of payment and inputting the
                          amount click on “Make Payment” to complete the
                          payment.
                        </li>
                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace25}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace255}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <p>
                          <b>- Daily reading Post: </b>make daily reading post
                          for the days reading
                        </p>

                        <li>
                          Click on the “Add post” to make a post, click on Daily
                          reading post
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace26}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          Choose a date you want to post the day’s reading for
                        </li>
                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace27}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace277}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <li>
                          Add any description of your choice to your daily
                          reading post
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace28}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          Click on “View Daily Reading” to view the day’s
                          reading which you have selected
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace29}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>Add a media to your post</li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace30}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          Click on the icon at the top right corner of the
                          screen to complete your post
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace31}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          You can view your post from the profile you used to
                          make the post
                        </li>
                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace32}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Ministryplace322}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </ul>
                    </li>

                    <li>
                      <p> Donations and Fundraising</p>
                      <p>Facilitate donations with ease:</p>
                      <p>
                        - Multiple payment options, including various currencies
                      </p>
                      <p>- Secure transaction processing</p>
                      <p>- Real-time donation tracking and reporting</p>
                      <p>- Campaign creation and management</p>
                    </li>

                    <li>
                      <p>Communication Tools</p>
                      <p>Engage with your community through:</p>
                      <p>- Direct messaging</p>
                      <p>- Prayer request submissions</p>

                      <ul style={{ listStyleType: "disc" }}>
                        <li>
                          You can view prayer request made on your ministry
                          place
                        </li>
                        <p>➢ Click on the profile of the ministry place</p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace33}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>
                          ➢ On the profile, just below the place promotional
                          button click on “Prayer Request” a bottom sheet will
                          pop up
                        </p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace34}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>
                          ➢ Click on “View prayer request” to view your requests
                        </p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace35}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          You can also edit the amount and currency to request
                          for a prayer
                        </li>
                        <p>➢ Click on the profile of the ministry place</p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace36}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>
                          ➢ On the profile, just below the place promotional
                          button click on “Prayer Request” a bottom sheet will
                          pop up
                        </p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace37}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>
                          ➢ Click on “Edit prayer request currency” to update
                          your requests currency
                        </p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace38}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>➢ Click on “Update” to save your changes</p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Ministryplace39}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>
                      </ul>
                    </li>

                    <li>
                      <p> Media Sharing</p>
                      <p>Enhance your posts with rich media:</p>
                      <p>
                        - Upload and share photos, videos, and audio recordings
                      </p>
                      <p>- Media library for storing and organizing content</p>
                    </li>

                    <li>
                      <p>Analytics and Reporting</p>
                      <p>
                        Track the performance and impact of your ministry
                        activities:
                      </p>
                      <p>
                        - Detailed analytics on posts, events, and fundraising
                        campaigns
                      </p>
                      <p>- User engagement metrics</p>

                      <p>- Financial reports on donations and expenditures</p>
                    </li>
                  </ol>

                  <h2>Best Practices</h2>
                  <p>
                    - Engage Regularly: Post updates frequently to keep your
                    community engaged.
                  </p>
                  <p>
                    - Be Visual: Use images and videos to make your posts more
                    appealing.
                  </p>
                  <p>
                    - Promote Events Early: Give your community ample time to
                    plan for upcoming events.
                  </p>
                  <p>
                    - Express Gratitude: Always thank your donors and supporters
                    to build a loyal community
                  </p>
                  <p>
                    - Monitor Analytics: Use the analytics tools to understand
                    what works best and adjust your strategies accordingly.
                  </p>

                  <h2>Support</h2>
                  <p>
                    For additional help or inquiries, please reach out to our
                    support team through the Vigoplace ticket system or support
                    email. We're here to assist you with any questions or issues
                    you may have
                  </p>

                  <p>---</p>

                  <p>
                    By following this documentation, you can effectively utilize
                    Ministry Place to foster a vibrant, supportive, and engaged
                    faith community.
                  </p>
                </section>

                {/* <!--//section--> */}
              </article>
              {/* <!--//docs-article--> */}
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}
