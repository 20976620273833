import React from "react";
import Groupplace1 from "../assets/images/Group place doc Image[1].jpg";
import Groupplace2 from "../assets/images/Group place doc Image[2].jpg";
import Groupplace222 from "../assets/images/Group place doc Image[222].jpg";
import Groupplace3 from "../assets/images/Group place doc Image[3].jpg";
import Groupplace4 from "../assets/images/Group place doc Image[4].jpg";
import Groupplace5 from "../assets/images/Group place doc Image[5].jpg";
import Groupplace6 from "../assets/images/Group place doc Image[6].jpg";
import Groupplace7 from "../assets/images/Group place doc Image[7].jpg";
import Groupplace77 from "../assets/images/Group place doc Image[77].jpg";
import Groupplace8 from "../assets/images/Group place doc Image[8].jpg";
import Groupplace9 from "../assets/images/Group place doc Image[9].jpg";
import Groupplace10 from "../assets/images/Group place doc Image[10].jpg";
import Groupplace100 from "../assets/images/Group place doc Image[100].jpg";
import Groupplace11 from "../assets/images/Group place doc Image[11].jpg";
import Groupplace12 from "../assets/images/Group place doc Image[12].jpg";
import Groupplace13 from "../assets/images/Group place doc Image[13].jpg";
import Groupplace14 from "../assets/images/Group place doc Image[14].jpg";
import Groupplace15 from "../assets/images/Group place doc Image[15].jpg";
import Groupplace16 from "../assets/images/Group place doc Image[16].jpg";
import Groupplace166 from "../assets/images/Group place doc Image[166].jpg";
import Groupplace17 from "../assets/images/Group place doc Image[17].jpg";
import Groupplace18 from "../assets/images/Group place doc Image[18].jpg";
import Groupplace19 from "../assets/images/Group place doc Image[19].jpg";
import Groupplace20 from "../assets/images/Group place doc Image[20].jpg";
import Groupplace21 from "../assets/images/Group place doc Image[21].jpg";
import Groupplace22 from "../assets/images/Group place doc Image[22].jpg";
import Groupplace23 from "../assets/images/Group place doc Image[23].jpg";
import Groupplace24 from "../assets/images/Group place doc Image[24].jpg";
import Groupplace244 from "../assets/images/Group place doc Image[244].jpg";
import Groupplace25 from "../assets/images/Group place doc Image[25].jpg";
import Groupplace26 from "../assets/images/Group place doc Image[26].jpg";
import Groupplace27 from "../assets/images/Group place doc Image[27].jpg";
import Groupplace28 from "../assets/images/Group place doc Image[28].jpg";
import Groupplace288 from "../assets/images/Group place doc Image[288].jpg";
//import Sidebar from "../components/Sidebar";

export default function GroupPlace() {
  //   const settings = {
  //     dots: true,
  //     dotsClass: "slick-dots slick-thumb",
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 3,
  //     initialSlide: 0,
  //     adaptiveHeight: true,
  //     // variableWidth: true,
  //     className: "slides",
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: 3,
  //           slidesToScroll: 3,
  //           infinite: true,
  //           dots: true,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 2,
  //           initialSlide: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 480,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //         },
  //       },
  //     ],
  //   };

  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="ministryplace">
                <header className="docs-header">
                  <h1 className="docs-heading">Group Place</h1>
                  <section className="docs-intro docs-section">
                    <p>
                      Group Place is a feature on Vigoplace designed to
                      facilitate collaboration, communication, and engagement
                      within specific groups or communities. Whether you're
                      managing a team, organizing an event, or fostering a
                      community around shared interests, Group Place provides
                      the tools you need to create and maintain a vibrant,
                      interactive space. This documentation provides a detailed
                      guide on how to create, manage, and utilize Group Place.
                    </p>
                  </section>
                </header>

                <section className="docs-section" id="create">
                  <h3>Creating a Group Place</h3>
                  <ol>
                    <li>
                      <p>Navigate to Group Place Section</p>
                      <p>- Log in to your Vigoplace account.</p>

                      <p>
                        - Click on the “+” sign at the top left of the screen to
                        access the place creating screen
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Groupplace1}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>- From the main menu, select "Create Group Place".</p>

                      <div className="simplelightbox-gallery row">
                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Groupplace2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Groupplace222}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </li>

                    <li>
                      <p> Set Up Group Place</p>

                      <p>
                        - Upload a profile picture and cover photo that
                        represent your Group Place.
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Groupplace3}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>- Name: Enter a unique name for your Group Place.</p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Groupplace4}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Type of group: The type of group is different from the
                        category of the group is emphasis on the type of
                        personnels and what the group is all about
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Groupplace5}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Category: Select an appropriate category that best
                        represents your group.
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Groupplace6}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        {" "}
                        - Group Place Address (Country, state): The exact
                        address where the place is been created or where the
                        group is located.
                      </p>

                      <div className="simplelightbox-gallery row">
                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Groupplace7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Groupplace77}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>

                      <p>
                        - Description: Provide a detailed description outlining
                        the purpose and goals of the group.
                      </p>

                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Groupplace8}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>
                    </li>

                    <li>
                      <p> Review and Publish</p>
                      <p>- Review all the details you have entered.</p>
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Groupplace9}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>

                      <p>
                        - Click the "Create" button to publish your Group Place.
                      </p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Groupplace10}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>

                        <div className="col-6 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Groupplace100}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>

                      <p>Managing Group Place</p>
                      <ol>
                        <li>Admin Tools and Permissions</li>
                        <p>
                          {" "}
                          As a group admin, you have access to various tools to
                          manage the group effectively
                        </p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Groupplace11}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>- Send invitations to potential new members.</p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Groupplace12}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p> - Approve or deny membership requests.</p>
                        <p> - Check pending approvals</p>
                        <p>
                          - Assign roles such as co-admins to help manage the
                          group.
                        </p>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Groupplace13}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>- Remove members who violate group rules.</p>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Groupplace14}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p> - Remove admins</p>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Groupplace15}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p> - Edit your group place information</p>
                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Groupplace16}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Groupplace166}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <p> - Delete group</p>

                        <li> Post Types and Content Management</li>
                        <p>
                          - Create different types of posts including text,
                          images, videos, and links.
                        </p>
                        <ul>
                          <li>
                            Click on the “Add post” to make a post, click on
                            Group place post
                          </li>
                          <li>
                            Choose the kind of post you want to make if it's a
                            Fixed post or Unfixed post
                          </li>

                          <p>
                            ➢ Fixed post gives you the opportunity to set fixed
                            amount for your post
                          </p>
                          <ul>
                            <li>Select Fixed post type</li>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace17}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <li>Put up a description for your post.</li>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace18}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <li>
                              Enter the fixed amount you want your contributors
                              to contribute with.
                            </li>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace19}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <li>Select a currency for your contribution</li>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace20}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <p>
                              ➢ Unfixed post allows yours contribute in any
                              amount of their choice on your post
                            </p>

                            <li> Select Unfixed post type</li>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace21}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <p>Put up a description for your post.</p>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace22}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <li>Select a currency for your contribution</li>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace23}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <li>Add a media to your post </li>
                            <div className="simplelightbox-gallery row">
                              <div className="col-6 mb-3">
                                <img
                                  className="figure-img img-fluid shadow rounded"
                                  src={Groupplace24}
                                  alt=""
                                  title="CoderPro Home Page"
                                />
                              </div>

                              <div className="col-6 mb-3">
                                <img
                                  className="figure-img img-fluid shadow rounded"
                                  src={Groupplace244}
                                  alt=""
                                  title="CoderPro Home Page"
                                />
                              </div>
                            </div>

                            <li>
                              Click on the icon at the top right corner of the
                              screen to complete your post
                            </li>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace25}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <li>
                              You can view your post from the profile you used
                              to make the post
                            </li>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace26}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <li>
                              You can view your givers (Contributors) by going
                              to the post then click on the “All Givers” text at
                              the bottom left corner of the media to view all
                              your givers
                            </li>
                            <div className="slideContainer2 simplelightbox-gallery">
                              <div className="simplelightbox-gallery row">
                                <div className="col-12 mb-3">
                                  <img
                                    className="figure-img img-fluid shadow rounded"
                                    src={Groupplace27}
                                    alt=""
                                    title="CoderPro Home Page"
                                  />
                                </div>
                              </div>
                            </div>

                            <li>
                              To make an offering, click on the “Give” text at
                              the bottom right of the media to make your
                              contributions. You can make the payment using your
                              wallet or debit card, after choosing a means of
                              payment and inputting the amount (for an unfixed
                              post) click on “Make Payment” to complete the
                              payment.
                            </li>
                            <div className="simplelightbox-gallery row">
                              <div className="col-6 mb-3">
                                <img
                                  className="figure-img img-fluid shadow rounded"
                                  src={Groupplace28}
                                  alt=""
                                  title="CoderPro Home Page"
                                />
                              </div>

                              <div className="col-6 mb-3">
                                <img
                                  className="figure-img img-fluid shadow rounded"
                                  src={Groupplace288}
                                  alt=""
                                  title="CoderPro Home Page"
                                />
                              </div>
                            </div>

                            <p>
                              - Pin important posts to keep them at the top of
                              the group feed.
                            </p>
                            <p>
                              - Encourage interaction by liking, commenting, and
                              sharing posts within the group.
                            </p>
                          </ul>
                        </ul>
                      </ol>

                      <h5 className="mt-3">Utilizing Group Place Features </h5>
                      <ol>
                        <li>Form Posts</li>
                        <p>
                          - Utilize Form Posts within your Group Place for
                          registrations, surveys, or feedback collection.
                        </p>
                        <p>- Create both Free and Paid Form Posts as needed.</p>

                        <li> Events and Announcements</li>
                        <p>
                          {" "}
                          - Organize group events and send out invitations to
                          members.
                        </p>
                        <p>
                          {" "}
                          - Make announcements and updates to keep members
                          informed about group activities.
                        </p>

                        <li> Group Analytics</li>
                        <p>
                          - Monitor group activity and engagement through
                          analytics tools.
                        </p>
                        <p>
                          - Track the growth of your group and measure the
                          impact of your posts and events
                        </p>
                      </ol>

                      <h5 className="mt-3">Engaging with Your Group</h5>
                      <ol>
                        <li> Regular Updates</li>
                        <p>
                          - Keep your group active by posting regular updates
                          and engaging content.
                        </p>
                        <p>
                          - Respond to member queries and comments promptly.
                        </p>

                        <li> Encourage Participation</li>
                        <p>
                          - Foster a sense of community by encouraging members
                          to contribute and share their own content.
                        </p>
                        <p>
                          - Recognize and appreciate active members to motivate
                          participation.
                        </p>
                      </ol>
                    </li>

                    <h3>Support and Assistance</h3>

                    <p>
                      For any issues or questions related to Group Place, users
                      can access support through the following channels:
                    </p>

                    <p>
                      - Ticket System: Submit a support ticket via the Vigoplace
                      app.
                    </p>
                    <p>
                      - Support Email: Contact support at
                      admin.place@Vigoplace.com
                    </p>

                    <h3>Conclusion</h3>

                    <p>
                      Group Place on Vigoplace is a powerful feature designed to
                      bring people together around shared interests and goals.
                      By following this documentation, users can effectively
                      create, manage, and engage with their Group Place,
                      fostering a vibrant and interactive community.
                    </p>
                  </ol>
                </section>

                {/* <!--//section--> */}
              </article>
              {/* <!--//docs-article--> */}
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}
