import React from "react";
import Slider from "react-slick";
import Ios from "../assets/images/ios.png";
import Android from "../assets/images/android.png";
import Contestplace from "../assets/images/contestplace.jpg";
import Contestplace2 from "../assets/images/contestplace2.jpg";
import Contestplace3 from "../assets/images/contestplace3.jpg";
import Contestplace3b from "../assets/images/contestplace3b.jpg";
import Paidcontestplace1 from "../assets/images/paidcontestplace1.jpg";
import Paidcontestplace2 from "../assets/images/paidcontestplace2.jpg";
import Freecontestplace from "../assets/images/freecontestplace.jpg";
import Tapcreate from "../assets/images/tapcreate.jpg";
import Createdcontest from "../assets/images/createdcontest.jpg";
import Editcontest1 from "../assets/images/editcontest1.jpg";
import Editcontest2 from "../assets/images/editcontest2.jpg";
import Editcontest3 from "../assets/images/editcontest3.jpg";
import Editcontest4 from "../assets/images/editcontest4.jpg";
import Editcontest5 from "../assets/images/editcontest5.jpg";
import Editcontest6 from "../assets/images/editcontest6.jpg";
import Contestant1 from "../assets/images/contestant1.jpg";
import Contestant2 from "../assets/images/contestant2.jpg";
import Contestant3 from "../assets/images/contestant3.jpg";
import Contestant4 from "../assets/images/contestant4.jpg";
import Contestant5 from "../assets/images/contestant5.jpg";
import Contestant6 from "../assets/images/contestant6.jpg";
import Contestant7 from "../assets/images/contestant7.jpg";
import Contestant8 from "../assets/images/contestant8.jpg";
import Managecontestant1 from "../assets/images/managecontestant1.jpg";
import Managecontestant2 from "../assets/images/managecontestant2.jpg";
import Managecontestant3 from "../assets/images/managecontestant3.jpg";
import Managecontestant4 from "../assets/images/managecontestant4.jpg";
import Managecontestant5 from "../assets/images/managecontestant5.jpg";
import Managecontestant6 from "../assets/images/managecontestant6.jpg";
import Vote from "../assets/images/vote1.jpg";
import Vote2 from "../assets/images/vote2.jpg";
import Vote3 from "../assets/images/vote3.jpg";
import Vote4 from "../assets/images/vote4.jpg";
import Vote5 from "../assets/images/vote5.jpg";
import Vote6 from "../assets/images/vote6.jpg";
import Vote7 from "../assets/images/vote7.jpg";
import Vote8 from "../assets/images/vote8.jpg";
import Vote9 from "../assets/images/vote9.jpg";
import Walletpayment1 from "../assets/images/walletpayment1.jpg";
import Walletpayment2 from "../assets/images/walletpayment2.jpg";
import Walletpayment3 from "../assets/images/walletpayment3.jpg";
import Bankpayment1 from "../assets/images/bankpayment1.jpg";
import Bankpayment2 from "../assets/images/bankpayment2.jpg";
import Bankpayment3 from "../assets/images/bankpayment3.jpg";

import { Link } from "react-router-dom";
import Sidebar from "../components/Sidebar";

export default function ContestPlace() {
  const settings = {
    dots: true,
    dotsClass: "slick-dots slick-thumb",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    adaptiveHeight: true,
    // variableWidth: true,
    className: "slides",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="contestplace">
                <header className="docs-header">
                  <h1 className="docs-heading">Contest Place</h1>
                  <section className="docs-intro docs-section">
                    <p>
                      Contest Place is one of the four places on offer from
                      Vigoplace for a user to choose from and create. This place
                      is primarily for users who wish to create and manage
                      either paid or unpaid voting competitions on their page. A
                      Contest Place page consists of three tabs:
                    </p>
                    <ol>
                      <li>
                        <b>Contestants</b> - The Contestants tab shows all
                        created contestants.
                      </li>
                      <li>
                        <b>Posts</b> - The Posts tab shows all basic posts
                        created in that place.
                      </li>
                      <li>
                        <b>Results</b> - The Results tab, as the name implies,
                        shows the results of each contestant, from number of
                        votes, to number of comments and likes. The Results tab
                        also gives the owner of the Contest Place the ability to
                        activate or deactivate a contestant and arrange the
                        contestant list for easy sorting.
                      </li>
                    </ol>
                    <p>
                      A Contest Place can either be in an activated or
                      deactivated state. If a Contest Place is deactivated, a
                      contestant can neither be activated nor deactivated on the
                      Results tab. Also, the contestants will not be visible
                      anywhere on the Vigoplace app except on the creator’s
                      Contest Place. This feature of controlling the state of a
                      Contest Place is great for controlling when voting
                      commences and ends. An activated Contest Place signifies
                      the contestants of the Contest Place is open for
                      interaction (voting, likes, and comments).
                    </p>
                  </section>
                  {/* <!--//docs-intro--> */}
                </header>

                <section className="docs-section" id="create">
                  <h3>How to create a Contest Place:</h3>
                  <p>
                    In order to create a Contest Place, activate your wallet by
                    activating at least one currency in your wallet. To learn
                    how to activate your wallet,{" "}
                    <a href="https://www.vigoplace.com">click here </a>
                  </p>
                  <p>After activation of your wallet, follow these steps:</p>
                  <ol>
                    <li>Tap on the “+” button</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestplace}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>Select “Contest Place” </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestplace2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Fill all the fields with the required detail and tap
                      “Next: Contest Settings” to create the settings of the
                      Contest Place
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestplace3}
                            alt=""
                            title="CoderPro Home Page"
                          />
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestplace3b}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Toggle properties and set quantity according to the field.
                      There are two types of Contest Place; paid (4a) and free
                      (4b).
                      <ul>
                        <li>
                          A paid Contest Place affords the owner to dictate the
                          voting currency/currencies, and number of votes per
                          contestant or unlimited number of votes per
                          contestant. The voting currencies available to set are
                          the currencies activated on the user’s account (4ai).
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <p>(4a)</p>{" "}
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Paidcontestplace1}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Paidcontestplace2}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>{" "}
                          <p>(4ai)</p>
                        </div>
                        <li>
                          A free Contest Place is the simplistic type that
                          allows only one free vote pre contestant
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Freecontestplace}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>
                      </ul>
                    </li>

                    <li>
                      After choosing what you want, tap on the “create a contest
                      place” button
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Tapcreate}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>

                    <li>Congrats, a Contest Place has been created.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Createdcontest}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                  </ol>
                </section>

                <section className="docs-section" id="benefit">
                  <h3>Benefits of a Contest Place:</h3>
                  <ol>
                    <li>Create, host, and manage contests.</li>
                    <li>Money generation via hosting of paid contests.</li>
                    <li>
                      Activation or deactivation of the Contest Place to control
                      when voting commences.
                    </li>
                    <li>Create and manage contestants.</li>
                    <li>
                      Easy sorting of contestants with different metrics such as
                      number of likes, comments, votes etc.
                    </li>
                    <li>Record keeping of past contests and results.</li>
                  </ol>
                </section>

                <section className="docs-section" id="edit">
                  <h3>Edit Contest Place</h3>
                  <p>
                    A Contest Place can be edited for the purpose of updating
                    the details used during creation. To edit a Contest Place,
                    follow these steps:
                  </p>

                  <ol>
                    <li>
                      1. Tap on the name of the Place you currently are at the
                      top of the screen (1a) to reveal list of available places,
                      and select the Contest Place you have already created
                      (1b)(1c)
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Editcontest1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(1a)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Editcontest2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(1b)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Editcontest3}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(1c)</p>
                    </div>
                    <li>Click on the “Edit Place” button</li>
                    <li>
                      On the “Edit Place” screen, there are two separate
                      sections you can decide to edit, either the profile
                      information of the Contest Place (3a) or the contest
                      settings (3b). After editing the properties of the section
                      you want, tap on the top right button to save the changes
                      of THAT section only (3c) and you are done
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Editcontest4}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(3a)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Editcontest5}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(3b)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Editcontest6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(3c)</p>
                    </div>
                  </ol>
                </section>

                <section className="docs-section" id="create-contestants">
                  <h3>Create Contestants</h3>
                  <p>To create contestants, kindly follow these steps:</p>
                  <ol>
                    <li>
                      Tap on the name of the Place you currently are at the top
                      of the screen (1a) to reveal list of available places, and
                      select the Contest Place you have already created (1b)
                      (1c).
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestant1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(1a)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestant2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(1b)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestant3}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(1c)</p>
                    </div>
                    <li>
                      Tap on “Add Post” to reveal post options available Contest
                      Place.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestant4}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>Tap on “Create a contestant”</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestant5}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      Select fill the required fields and select either a video
                      or pictures of that contestant (4a) and tap on the top
                      right icon to create a contestant (4b).
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestant6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(4a)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestant7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(4b)</p>
                    </div>
                    <li>
                      NOTE: you can add a link of the contestant on the link
                      textbox provided for you.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestant8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>Voila! A contestant has been created.</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Contestant8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                  </ol>
                </section>

                <section className="docs-section" id="manage-contestants">
                  <h3>Manage Contestants</h3>
                  <p>
                    Managing contestants in your Contest Place is as easy as it
                    comes. In addition to managing contestants, the owner of the
                    Contest Place can easily arrange the contestants for easy
                    sorting. To manage contestants, follow these steps:
                  </p>
                  <ol>
                    <li>
                      Tap on the name of the Place you currently are at the top
                      of the screen (1a) to reveal list of available places, and
                      select the Contest Place you have already created (1b)
                      (1c).
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Managecontestant1}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(1a)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Managecontestant2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(1b)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Managecontestant3}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(1c)</p>
                    </div>
                    <li>
                      Tap on the “Results” tab to display a detailed statistic
                      of each individual contestant
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Managecontestant4}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      In order to activate or deactivate a contestant, you must
                      first activate the Contest Place. To activate the Contest
                      Place, tap on the “Activate” button (3a). Once activated,
                      you will receive a prompt (3b).
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Managecontestant5}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(3a)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Managecontestant6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(3b)</p>
                    </div>
                  </ol>
                </section>

                <section className="docs-section" id="voting">
                  <h3>Voting</h3>
                  <p>
                    The purpose of a Contest Place is to host and manage either
                    paid or free competitions. To vote for your favorite
                    contestant is easy, all you have to do is follow these
                    steps.
                  </p>

                  <ol>
                    <li>Find the post with your favorite contestant</li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Vote}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                    </div>
                    <li>
                      You can tap on “Show cost” (2a) to view the cost of each
                      vote in currencies dictated by the contest host (2b). If
                      it a free contest, there will be no “Show cost”.
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Vote2}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(2a)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Vote3}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(2b)</p>
                    </div>
                    <li>
                      Tap on “Vote” (3a) and click on “Vote” when the dialog box
                      asks you if you want to vote for your preferred contestant
                      (3b).
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Vote4}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(3a)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Vote5}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(3b)</p>
                    </div>
                    <li>
                      If the contest is a free contest, your singular vote is
                      casted for that contestant. If it is a paid contest, you
                      will be taken to the next screen (4a) to select currency
                      (4b) and specify the number of votes (4c). After setting
                      the desired properties to vote, tap on the “Next” button
                      at the bottom of the screen to select payment method
                    </li>
                    <div className="slideContainer2 simplelightbox-gallery">
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Vote6}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(4a)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Vote7}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(4b)</p>
                      <div className="simplelightbox-gallery row">
                        <div className="col-12 mb-3">
                          <img
                            className="figure-img img-fluid shadow rounded"
                            src={Vote8}
                            alt=""
                            title="CoderPro Home Page"
                          />
                        </div>
                      </div>
                      <p>(4c)</p>
                    </div>
                    <li>
                      Select payment method, and tap on the “Next” button at the
                      bottom of your screen.
                      <div className="slideContainer2 simplelightbox-gallery">
                        <div className="simplelightbox-gallery row">
                          <div className="col-12 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Vote9}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>
                      </div>
                      <ul>
                        <li>
                          For wallet payment, tap on the “Yes” button to cast
                          your vote (5ia). If voting is successful, you will get
                          a success prompt (5ib), and you will be taken back to
                          post to see your vote added (5ic).
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Walletpayment1}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                          <p>(5ia)</p>
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Walletpayment2}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                          <p>(5ib)</p>
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Walletpayment3}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                          <p>(5ic)</p>
                        </div>
                        <li>
                          For bank payment, tap on the “Yes, vote” button to
                          cast your vote (5ia), and you will be presented with a
                          payment modal to input your card details (5ib). If
                          voting is successful, you will get a success prompt,
                          and you will be taken back to post to see your vote
                          added (5ic).
                        </li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Bankpayment1}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                          <p>(5ia)</p>
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Bankpayment2}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                          <p>(5ib)</p>
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Bankpayment3}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                          <p>(5ic)</p>
                        </div>
                      </ul>
                    </li>
                  </ol>
                </section>

                {/* <!--//section--> */}
              </article>
              {/* <!--//docs-article--> */}
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}
