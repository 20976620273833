import React from "react";
import Basicplace2 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[2].jpg";
import Basicplace3 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[3].jpg";
import Basicplace4 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[4].jpg";
import Basicplace44 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[44].jpg";
import Basicplace5 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[5].jpg";
import Basicplace55 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[55].jpg";
import Basicplace6 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[6].jpg";
import Basicplace7 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[7].jpg";
import Basicplace8 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[8].jpg";
import Basicplace9 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[9].jpg";
import Basicplace10 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[10].jpg";
import Basicplace100 from "../assets/images/Basic_Place_Documentation_for_Vigoplace_(1)[1] Image[100].jpg";
import Ministryplace4 from "../assets/images/ministry_place_docs[1] Image[4].jpg";
import Ministryplace13 from "../assets/images/ministry_place_docs[1] Image[13].jpg";
import Ministryplace133 from "../assets/images/ministry_place_docs[1] Image[133].jpg";

//import Sidebar from "../components/Sidebar";

export default function GroupPlace() {
  //   const settings = {
  //     dots: true,
  //     dotsClass: "slick-dots slick-thumb",
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 3,
  //     initialSlide: 0,
  //     adaptiveHeight: true,
  //     // variableWidth: true,
  //     className: "slides",
  //     responsive: [
  //       {
  //         breakpoint: 1024,
  //         settings: {
  //           slidesToShow: 3,
  //           slidesToScroll: 3,
  //           infinite: true,
  //           dots: true,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 2,
  //           slidesToScroll: 2,
  //           initialSlide: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 480,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //         },
  //       },
  //     ],
  //   };

  return (
    <>
      <div className="docs-page">
        <div className="docs-wrapper">
          {/* <Sidebar /> */}

          {/* <!--//docs-sidebar--> */}

          <div className="docs-content">
            <div className="container">
              <article className="docs-article" id="ministryplace">
                <header className="docs-header">
                  <h1 className="docs-heading">Basic Place</h1>
                  <section className="docs-intro docs-section">
                    <p>
                      The Basic Place on Vigoplace is the foundation for all
                      user profiles, serving as the universal starting point for
                      personal, business, and community activities. This
                      documentation will guide you through setting up a Basic
                      Place and creating different post types within it.
                    </p>
                  </section>
                </header>

                <section className="docs-section" id="create">
                  <h3>Key Features</h3>
                  <ol>
                    <li>
                      <p>Profile Setup</p>
                      <p>
                        - Customizable Profile: Personalize your Basic Place
                        with profile pictures, cover photos, and descriptions.
                      </p>

                      <p>How to Create a Basic Place</p>

                      <ul>
                        <li>Log in to your Vigoplace account.</li>
                        <li>
                          Click on the “+” sign at the top left of the screen to
                          access the place creating screen
                        </li>

                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Basicplace2}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>Choose "Basic Place" from the available options</li>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Basicplace3}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <li>
                          Add your profile picture, name, location, category and
                          description.
                        </li>
                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Basicplace4}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Basicplace44}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <li>
                          Once you've configured your profile, click "Create
                          place" to finalize the setup.
                        </li>
                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Basicplace5}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Basicplace55}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <p>
                          {" "}
                          - Multi-Wallet Integration: Manage various currency
                          wallets for transactions, supporting both local and
                          international payments.
                        </p>
                      </ul>
                    </li>

                    <li>
                      <p>Post Types</p>

                      <p>
                        Basic Post: The most common post type available across
                        all Place profiles on Vigoplace. It allows users to
                        share updates, images, and other content seamlessly
                      </p>

                      <p>How to Make Different Posts in Basic Place</p>

                      <ol>
                        <li>Creating a Basic Post:</li>
                        <p>
                          - Navigate to Post Creation by clicking on the add
                          post at the bottom of the screen{" "}
                        </p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Basicplace6}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p> - On your Basic Place page, click "Basic Post."</p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Basicplace7}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p> - On your Basic Place page, click "Basic Post."</p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Basicplace8}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>- Add images, or other media.</p>
                        <div className="slideContainer2 simplelightbox-gallery">
                          <div className="simplelightbox-gallery row">
                            <div className="col-12 mb-3">
                              <img
                                className="figure-img img-fluid shadow rounded"
                                src={Basicplace9}
                                alt=""
                                title="CoderPro Home Page"
                              />
                            </div>
                          </div>
                        </div>

                        <p>
                          - Click on the icon at the top right corner of the
                          screen to complete your post
                        </p>
                        <div className="simplelightbox-gallery row">
                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Basicplace10}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>

                          <div className="col-6 mb-3">
                            <img
                              className="figure-img img-fluid shadow rounded"
                              src={Basicplace100}
                              alt=""
                              title="CoderPro Home Page"
                            />
                          </div>
                        </div>

                        <p>
                          - News Post: Share news, announcements, or updates
                          relevant to your audience. Ideal for keeping followers
                          informed about important events or developments.
                        </p>
                      </ol>

                      <li>
                        <p>Community Engagement</p>
                        <p>
                          - Vibes and Reactions: Engage with posts through
                          various reactions, enabling quick and expressive
                          interactions.
                        </p>
                        <p>
                          - Voice Commenting: Enhance engagement by leaving
                          voice comments on posts, adding a personal touch to
                          your interactions
                        </p>
                        <p>
                          {" "}
                          - Profile Promotion: Increase visibility and earn from
                          your activities on Vigoplace by promoting your profile
                          within the network
                        </p>
                      </li>

                      <li>
                        <p> Financial Features</p>
                        <p>
                          - Secured Transactions: Utilize secured online banking
                          with features like OTP for transfers, inter-bank
                          transfers, and expense tracking.
                        </p>
                        <p>
                          - Transaction Management: Access detailed records of
                          all transactions, helping you manage your finances
                          efficiently.
                        </p>
                      </li>

                      <li>
                        <p> Support and Assistance</p>
                        <p>
                          {" "}
                          - Customer Support: Get help through a ticket system
                          or email, ensuring that any issues related to your
                          Basic Place are promptly addressed.
                        </p>
                        <p>
                          {" "}
                          - KYC and Security: Protect your profile with KYC
                          verification and robust security measures to guard
                          against unauthorized access.
                        </p>
                      </li>
                    </li>
                  </ol>

                  <h2>Post Types Overview</h2>
                  <p>
                    - Basic Post: The first and most universal post type
                    available on Vigoplace, essential for all profiles.
                  </p>
                  <p>
                    - News Post: Keeps followers updated with news and
                    announcements.
                  </p>
                  <p>
                    - Form Post: Allows for easy collection of information
                    through customizable forms.
                  </p>

                  <h2>Use Cases</h2>
                  <p>
                    - Personal Profile: Share updates, connect with friends, and
                    engage in community activities
                  </p>

                  <p>
                    - Community Groups: Organize activities, share news, and
                    collect donations or member information through Form Posts.
                  </p>

                  <h2>Conclusion</h2>
                  <p>
                    The Basic Place on Vigoplace is essential for all users,
                    providing a solid foundation for managing personal,
                    business, or community activities. With versatile post
                    types, financial tools, and community engagement features,
                    the Basic Place is designed to help users thrive in the
                    digital space.
                  </p>
                </section>

                {/* <!--//section--> */}
              </article>
              {/* <!--//docs-article--> */}
            </div>
          </div>
        </div>
        {/* <!--//docs-wrapper--> */}
      </div>
    </>
  );
}
